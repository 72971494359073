import React from 'react'
import { Link } from 'gatsby'
import { FiChevronRight } from 'react-icons/fi'

const reasons = [
  {
    title: 'A strategic approach',
    content:
      'Everything that you invest in PNPR will directly benefit your company because PNPR’s communications campaigns are built on and measured by your requirements. Communications campaigns are derived from your business objectives, your USP, your target audience and the messages that matter to you.',
  },
  {
    title: 'A creative, coordinated approach',
    content:
      'PNPR’s strategy for your business will be designed specifically for you, drawing on meetings, research and an analysis of your public profile to create a unique set of tactics to meet your requirements.  All work is carried out according to a strategy which has been discussed with and approved by you. Any changes to the programme are also agreed by you.',
  },
  {
    title: 'An informed approach',
    content:
      'As the author of three books on communications for the property sector and as a founder Chartered Member and Fellow of the CIPR, Penny Norton has substantial theoretical knowledge – as well as experience and contacts – which enables her to deliver to deliver outstanding communications campaigns.',
  },
  {
    title: 'A cost efficient approach',
    content:
      'Due to our low overheads, pricing is inexpensive for the quality of work provided. We can account for every minute of our fee, and don’t charge for administration. Services are flexible to take into account clients’ needs and budgets – for example you may wish to simply to invest in a strategy for your company’s own implementation; or you may wish to retain PNPR on an ongoing contract to deal with all of your communications requirements. Either option, or a combination of the two can be agreed, and adapted as required.',
  },
  {
    title: 'A communicative approach',
    content:
      'Client satisfaction is our top priority: campaigns are based firmly on clients’ objectives and we monitor feedback to ensure success. Reporting and administrative systems are flexible to suit your requirements and we account for all time spent through regular reporting.',
  },
]

const WhyChoosePNPR = () => (
  <div className="why-choose-pnpr-wrapper">
    <div>
      {reasons.map((r, i) => (
        <div className="reason" key={`reason-${i}`}>
          <FiChevronRight />
          <h5>{r.title}</h5>
          <div className="content">{r.content}</div>
        </div>
      ))}
    </div>
    <div className="mt-8 text-center sm:text-right">
      <Link to="/the-pnpr-approach" className="button pink inline-block">
        The PNPR Approach
      </Link>
    </div>
  </div>
)

export default WhyChoosePNPR
